.domain-home-continer {
    padding: 24px;
}

.domain-home-header {
    background: #F8F8F8;
    padding: 20px 40px;
    color: #021F3F;
}

.domain-home-data {
    padding: 20px 40px;
    color: #021F3F;
}

.domain-home-chip {
    background-color: #00DD641A;
    font-size: 12px;
    padding: 4px 6px;
    color: #00DD64;
    border-radius: 8px;
}

.domain-home-btn-container {
    background-color: #F8F8F8;
    padding: 24px 0;
}

.domain-add-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.domain-add-title {
    color: #021F3F;
    font-size: 22px;
    margin: 0;
}

.domain-add-details-title {
    background-color: #F8F8F8;
    color: #95989A;
    text-align: left;
    font-size: 16px;
    padding: 24px 0;
}