.theme-container {
    background-color: #F8F8F8;
}

.theme-container img {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

.featured-container,
.add-feature-container {
    background-color: white;
    padding: 24px;
    min-height: 50vh;
}

.show-featured-option{
    height: 24px !important;
    cursor: pointer;
}