.delivery-bg {
    background-color: #F8F8F8;
}

.single-delivery {
    background-color: white;
    margin-bottom: 24px;
    padding: 24px;
    text-align: left;
}

.delivery-title {
    color: #021F3F;
    font-size: 22px;
}

.delivery-info {
    color: #95989A;
    font-size: 16px;
}

.delivery-paperfly-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.delivery-paperfly-div>span {
    margin-left: 24px;
    color: #021F3F;
    font-size: 18px;
    font-weight: 500;
}

.delivery-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delivery-section .set-up-btn {
    background-color: #FF6550;
    padding: 8px 21px;
    border: 0;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    font-family: 'figtree';

}

.set-up-btn:hover {
    background-color: #ED5E4A;
}

.pickup-adrs-div {
    padding: 24px;
    text-align: left;
    min-height: 80vh;
}

.pickup-adrs-form {
    width: 70%;
}

/* .add-btn {
    margin-top: 150px;
}

.save-btn {
    margin-top: 200px;
} */

.place-right {
    display: flex;
    justify-content: flex-end;
}