.App {
  text-align: center;
  background-color: #f8f8f8;
  font-family: 'Figtree';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Figtree';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

main {
  width: 100%;
  padding: 20px;
}

.active {
  background: lightskyblue;
  color: #000;
}

.addImage {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #95989A;
  border-radius: 4px;
  width: 92px;
  height: 92px;
  cursor: pointer;
  font-size: 12px;
  color: #95989A;
}

.variantImage {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #95989A;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  /* padding-top: 50%; */
  /* font-size: 12px; */
  color: #95989A;

}

input {
  display: none;
}


.scroll::-webkit-scrollbar {
  display: none;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: '#95989A'
}

.collectionImage {
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #95989A;
  border-radius: 4px;
  width: 379px;
  height: 198px;
  cursor: pointer;
  font-size: 22px;
  color: #95989A;
}

.single-plugin-container {
  background-color: white;
  padding: 50px;
  margin: 0 100px;
}

.single-plugin-header {
  display: flex;
  justify-content: flex-start;
}

.single-plugin-header-right {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.single-plugin-title {
  color: #021F3F;
  font-size: 22px;
  font-weight: 500;
  margin: 20px 0;
}

.single-plugin-btn-div {
  margin: 80px 0 48px 0;
  text-align: left;
}

.single-plugin-description {
  color: #95989A;
  font-size: 18px;
  text-align: left;
}

.single-plugin-details-title {
  color: #79747E;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: 40px 0;
}

.single-plugin-ul {
  color: #95989A;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.single-plugin-li {
  list-style-type: disc;
  margin: 8px 0;
}

.single-plugin-bold {
  color: #79747E;
  font-size: 16px;
  font-weight: 500;
}

.ssl-dialogue-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ssl-dialogue-header>span {
  color: #021F3F;
  font-size: 24px;
  cursor: default;
}

.ssl-dialogue-header>img {
  cursor: pointer;
  margin-left: 100px;
}

.ssl-dialogue-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 25px;
}

.google-login .button {
  cursor: pointer;
  display: block;
  font-size: 15px;
  box-sizing: content-box;
  margin: 16px 0px;
  width: 90%;
  padding: 15.2px 20px;
  border-radius: 4px;
  border-color: transparent;
  background-color: white;
  border: 0.05px solid rgba(78, 79, 114, 0.1);
  /* box-shadow: 0px 16px 60px rgba(78, 79, 114, 0.1); */
  /* box-shadow: 0px 10px 20px rgba(78, 79, 114, 0.1); */
  position: relative;
}

.google-login .buttonText {
  color: #4285f4;
  font-weight: bold;
}

.google-login .icon {
  height: 20px;
  width: 20px;
  margin-right: 0px;
  position: absolute;
  left: 100px;
  align-items: center;
}

.add-campaign-addImage-div {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #95989A;
  border-radius: 4px;
  width: 380px;
  height: 196px;
  cursor: pointer;
  font-size: 12px;
  color: #95989A;
  text-align: center;
}