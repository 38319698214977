.bg{
    padding: 24px;
    text-align: left;
}
.addImage-div {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #95989A;
    border-radius: 4px;
    width: 160px;
    height: 160px;
    cursor: pointer;
    font-size: 12px;
    color: #95989A;
    text-align: center;
  }
.addImage-div-two {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #95989A;
    border-radius: 4px;
    width: 360px;
    height: 160px;
    cursor: pointer;
    font-size: 12px;
    color: #95989A;
    text-align: center;
  }
  .botton-div{
    display: flex;
    justify-content: flex-end;
  }