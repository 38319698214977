.bg-store {
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.content {
    background-color: white;
    /* margin: 90px 400px; */
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title{
    font-size: 24px;
    color: #95989A;
    margin: 30px 0 50px 0;
}

.info{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ED1C24;
}